import NorngainyImage from "../images/panelist/Norngainy.png";
import AlSiddikImage from "../images/panelist/AlSiddik.png";
import AndamarImage from "../images/panelist/Andamar.png";
import ImleeshImage from "../images/panelist/Imleesh.png";
import WyneImage from "../images/panelist/Wyne.png";
import NatalieImage from "../images/panelist/Natalie.png";
import CherryImage from "../images/panelist/Cherry.png";
import JacquilineImage from "../images/panelist/Jacquiline.png";
import SungImage from "../images/panelist/Sung.png";
import LytvynenkoImage from "../images/panelist/Lytvynenko.png";

export const InternationalPanelistData = {
    "type": "InternationalPanelist",
    "Collections": [
        {
            type: "InternationalPanelist",
            id: "1",
            properties:
            {
                name: "Dr. Norngainy Binti Mohd Tawil",
                institution: "Universiti Kebangsaan Malaysia",
                occupation : "Assoc. Prof., Deputy Dir.",
                photo: NorngainyImage
            }
        },
        {
            type: "InternationalPanelist",
            id: "2",
            properties:
            {
                name: "Natalie Duncan",
                institution: "Bug Mars",
                occupation : "Co-Founder & CEO",
                photo: NatalieImage
            }
        },
        {
            type: "InternationalPanelist",
            id: "3",
            properties:
            {
                name: "Cherry Murillon",
                institution: "CAWIL.AI",
                occupation : "Founder and CEO",
                photo: CherryImage
            }
        },
        {
            type: "InternationalPanelist",
            id: "4",
            properties:
            {
                name: "Jacquiline Romorosa",
                institution: "QWIKWIRE",
                occupation : "Co-Founder and COO",
                photo: JacquilineImage
            }
        },
        {
            type: "InternationalPanelist",
            id: "5",
            properties:
            {
                name: "Michael Sung",
                institution: "Director",
                occupation : "PT. Shine Ever Tex Tbk",
                photo: SungImage
            }
        },
        {
            type: "InternationalPanelist",
            id: "6",
            properties:
            {
                name: "Andamar Pradipta",
                institution: "Indonesia International Institute",
                occupation : "Head, Intl. Business Mgmt.",
                photo: AndamarImage
            }
        },
        {
            type: "InternationalPanelist",
            id: "7",
            properties:
            {
                name: "Dr. Mohamad A. Alsiddik",
                institution: "Assisted Living Marketing",
                occupation : "Project Manager and Consultant",
                photo: AlSiddikImage
            }
        },
        {
            type: "InternationalPanelist",
            id: "8",
            properties:
            {
                name: "Jamil Wyne",
                institution: "Riffle Ventures",
                occupation : "Founder and CEO",
                photo: WyneImage
            }
        },
        {
            type: "InternationalPanelist",
            id: "9",
            properties:
            {
                name: "Lytvynenko Tanya",
                institution: "Inventify Center",
                occupation : "Project Manager",
                photo: LytvynenkoImage
            }
        },
        {
            type: "InternationalPanelist",
            id: "10",
            properties:
            {
                name: "Mohammed Mahmoud Imleesh",
                institution: "GarmenChain",
                occupation : "Head of Project Developer",
                photo: ImleeshImage
            }
        }
    ]
};