export const BenefitData = {
    "type": "Benefit",
    "Collections": [
        {
            type: "Benefit",
            id: "1",
            properties:
            {
                benefit: "5 Sertifikat Internasional",
            }
        },
        {
            type: "Benefit",
            id: "2",
            properties:
            {
                benefit: "Portfolio Project + Review",
            }
        },
        {
            type: "Benefit",
            id: "3",
            properties:
            {
                benefit: "Medals, International Awards & Grand Prizes",
            }
        },
        {
            type: "Benefit",
            id: "4",
            properties:
            {
                benefit: "Berhadiah Laptop, Smartphone, dan Beasiswa",
            }
        },
        {
            type: "Benefit",
            id: "5",
            properties:
            {
                benefit: "Global Exposure & Collaboration",
            }
        },
        {
            type: "Benefit",
            id: "6",
            properties:
            {
                benefit: "30+ Template, E-book, & Video Courses",
            }
        },
        {
            type: "Benefit",
            id: "7",
            properties:
            {
                benefit: "Grup Whatsapp Eksklusif",
            }
        },
        {
            type: "Benefit",
            id: "8",
            properties:
            {
                benefit: "Offline Networking dengan Komunitas & Hiring partner!",
            }
        },
        {
            type: "Benefit",
            id: "9",
            properties:
            {
                benefit: "Merchandise dan Fasilitas Eksklusif Lainnya",
            }
        }
    ]
};