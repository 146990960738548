import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from 'aos';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import BadgeSection from "../badge/Badge";
import GridLayerImage from "../../assets/images/general/grid-layers.svg";

import WorkPlaceIcon from "../../assets/images/icons/work-place.svg";
import OccupationIcon from "../../assets/images/icons/occupation.svg";

import { InternationalPanelistData } from "../../assets/data/internationalPanelistData";

import 'swiper/css';
import 'swiper/css/pagination';

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const InternationalPanelistContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <div className="content-wrapper" style={{ position: "relative" }}>
                <div className={window.innerWidth < 576 ? "d-none" : "absolute"} style={{ position: "absolute", top: '-420px', left: 0, width: "100%", zIndex: 0 }}>
                    <Image src={GridLayerImage} style={{ width: "100%", height: "auto" }} loading="lazy" />
                </div>
                <Row>
                    <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up" style={{ zIndex: 1 }}>
                        <BadgeSection
                            sectionTitle="International Panelist"
                            widthBackground={window.innerWidth < 576 ? "160px" : "170px"}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: window.innerWidth < 576 ? "20px" : "30px" }}>
                    <Col xs={12} xl={{ span: 8, offset: 2 }} className="d-flex justify-content-center" data-aos="fade-up" style={{ zIndex: 1 }}>
                        <h1 className="text-center" style={{ fontSize: window.innerWidth < 576 ? "24px" : null }}>
                            Para <strong><i>International Panelist</i></strong> yang Membantumu Meningkatkan <strong><i>Global Exposure</i></strong>
                        </h1>
                    </Col>
                </Row>
                <Row style={{ marginTop: window.innerWidth < 576 ? "30px" : '40px' }}>
                    <Col xl={12}>
                        <div style={{ height: 'auto', width: '100%', borderRadius: '8px' }} data-aos="fade-up">
                            <Swiper
                                slidesPerView={window.innerWidth < 576 ? '1' : '4'}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                    dynamicBullets: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                                style={{ height: '100%' }}
                            >
                                {InternationalPanelistData.Collections.map((panelist) => {
                                    return (
                                        <SwiperSlide key={panelist.id} style={{ cursor: 'pointer' }}>
                                            <div className="d-flex flex-column" style={{ width: '100%', background: '#FFFFFF', border: '1px solid #E9ECF1', padding: '15px', borderRadius: '10px', gap: '15px' }}>
                                                <div>
                                                    <Image src={panelist.properties.photo} style={{ width: '100%', height: 'auto', borderRadius: '8px' }} loading="lazy"/>
                                                </div>
                                                <div className="d-flex flex-column" style={{ gap: '8px' }}>
                                                    <p style={{ margin: 'auto 0' }}><strong>{panelist.properties.name}</strong></p>
                                                    <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                                        <Image src={WorkPlaceIcon} />
                                                        <p style={{ margin: 'auto 0' }}>{panelist.properties.institution}</p>
                                                    </div>
                                                    <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                                        <Image src={OccupationIcon} />
                                                        <p style={{ margin: 'auto 0' }}>{panelist.properties.occupation}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default InternationalPanelistContent;