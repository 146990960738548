import Event1Image from "../images/event/Event1.jpg";
import Event2Image from "../images/event/IMTC2025.png";
import Event3Image from "../images/event/IPDM2025.png";
import Event4Image from "../images/event/IBDA2025.png";
import Event5Image from "../images/event/IMOB2025.png";
import Event6Image from "../images/event/IDMC2025.png";
import Event7Image from "../images/event/AIAB.png";
import EventDataScience from "../images/event/Event11.jpg";
import EventStartup from "../images/event/Event4.jpg";
import EventWebsite from "../images/event/Event2.jpg";
import EventCareer from "../images/event/Event13.jpg";
import EventFintech from "../images/event/Event12.jpg";

export const EventData = {
    "type": "Event",
    "Collections": [
        {
            type: "Event",
            id: "1",
            properties:
            {
                title: "INTERNATIONAL PRODUCT DEVELOPMENT MASTERY (IPDM) 2024",
                quota: "",
                image: Event1Image,
                link: "https://www.instagram.com/p/C_9TnTHB-EM/?img_index=2",
                finish: "yes"
            }
        },
        {
            type: "Event",
            id: "2",
            properties:
            {
                title: "INTERNATIONAL MANAGEMENT TRAINEE CLASS 2025",
                quota: "8",
                image: Event2Image,
                link: "https://docs.google.com/forms/d/e/1FAIpQLSd9Gghc_ZbWzZMUcJTxH2TFKQ80Ro8pfsVdp-cvhaFr5-TZ7w/viewform",
                finish: "no"
            }
        },
        {
            type: "Event",
            id: "3",
            properties:
            {
                title: "INTERNATIONAL PRODUCT DEVELOPMENT MASTERY (IPDM) 2025",
                quota: "9",
                image: Event3Image,
                link: "https://docs.google.com/forms/d/e/1FAIpQLScgAoNoPqRxRDupEpAc_R1G_irVyLlQDoTcGz6l1lR8XY9YiQ/viewform",
                finish: "no"
            }
        },
        {
            type: "Event",
            id: "4",
            properties:
            {
                title: "INTERNATIONAL BUSINESS DEVELOPMENT ACADEMY (IBDA) 2025",
                quota: "7",
                image: Event4Image,
                link: "https://docs.google.com/forms/d/e/1FAIpQLSd0POdvCvLfL7f3opUlrUr9LpPy6Ofgtl6DZnRSL70gKFv1Dg/viewform",
                finish: "no"
            }
        },
        {
            type: "Event",
            id: "5",
            properties:
            {
                title: "INTERNATIONAL MICROSOFT OFFICE BOOTCAMP 2025",
                quota: "8",
                image: Event5Image,
                link: "https://docs.google.com/forms/d/e/1FAIpQLSeIqTBLudFjuTFE89Oemgft6ZyF7qQRhZnckpNN6reAVYVP2g/viewform",
                finish: "no"
            }
        },
        {
            type: "Event",
            id: "6",
            properties:
            {
                title: "INTERNATIONAL DIGITAL MARKETING CLASS 2025",
                quota: "9",
                image: Event6Image,
                link: "https://docs.google.com/forms/d/e/1FAIpQLScffiw9EHmFRzk708Tg6uAUKgcnUxucNKPeUqJFQNQDXoYuuA/viewform",
                finish: "no"
            }
        },
        {
            type: "Event",
            id: "7",
            properties:
            {
                title: "AI AUTOMATION BUSINESS",
                quota: "8",
                image: Event7Image,
                link: "https://lynk.id/careertools/MbqLPVJ",
                finish: "no"
            }
        },
        {
            type: "Event",
            id: "8",
            properties:
            {
                title: "Startup Founder",
                quota: "",
                image: EventStartup,
                link: ""
            }
        },
        {
            type: "Event",
            id: "9",
            properties:
            {
                title: "Website Development",
                quota: "",
                image: EventWebsite,
                link: "",
            }
        },
        {
            type: "Event",
            id: "10",
            properties:
            {
                title: "Data Science",
                quota: "",
                image: EventDataScience,
                link: ""
            }
        },
        {
            type: "Event",
            id: "11",
            properties:
            {
                title: "Career & Self Development",
                quota: "",
                image: EventCareer,
                link: ""
            }
        },
        {
            type: "Event",
            id: "12",
            properties:
            {
                title: "Corporate Finance and Tax",
                quota: "",
                image: EventFintech,
                link: ""
            }
        },
    ]
};