import LmsImage1 from "../images/event/Event2.jpg";
import LmsImage2 from "../images/event/Event3.jpg";
import LmsImage3 from "../images/event/Event4.jpg";
import LmsImage4 from "../images/event/Event5.jpg";
import LmsImage5 from "../images/event/Event6.jpg";
import LmsImage6 from "../images/event/Event7.jpg";
import LmsImage7 from "../images/event/Event8.jpg";
import LmsImage8 from "../images/event/Event9.jpg";
import LmsImage9 from "../images/event/Event10.jpg";
import LmsImage10 from "../images/event/Event11.jpg";
import LmsImage11 from "../images/event/Event12.jpg";
import LmsImage12 from "../images/event/Event13.jpg";
import LmsImage13 from "../images/event/Event14.jpg";

import Ecourse1 from "../images/ecourse/canva.png";
import Ecourse2 from "../images/ecourse/chatgpt.png";
import Ecourse3 from "../images/ecourse/fbads.png";
import Ecourse4 from "../images/ecourse/instagramads.png";
import Ecourse5 from "../images/ecourse/digital-marketing.png";
import Ecourse6 from "../images/ecourse/template-ig.png";
import Ecourse7 from "../images/ecourse/template-ppt.png";
import Ecourse8 from "../images/ecourse/template-cv.png";
import Ecourse9 from "../images/ecourse/template-cv-2.png";
import Ecourse10 from "../images/ecourse/template-cv-3.png";

export const ProductData = {
    "type": "Product",
    "Collections": [
        {
            type: "E-Book",
            id: "1",
            properties:
            {
                products : [
                    {
                        id: "1",
                        title: "E-Book Website Development",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage1
                        ],
                        star: "4.5",
                        link: ""
                    },
                    {
                        id: "2",
                        title: "E-Book UI/UX Designer",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage2
                        ],
                        star: "4.5",
                        link: ""
                    },
                    {
                        id: "3",
                        title: "E-Book Startup Founder",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage3
                        ],
                        star: "5",
                        link: ""
                    },
                    {
                        id: "4",
                        title: "E-Book Product Development",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage4
                        ],
                        star: "4.5",
                        link: ""
                    },
                    {
                        id: "5",
                        title: "E-Book Microsoft Office (Fullstack)",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage5
                        ],
                        star: "4.5",
                        link: ""
                    },
                    {
                        id: "6",
                        title: "E-Book Human Resource Development",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage6
                        ],
                        star: "5",
                        link: ""
                    },
                    {
                        id: "7",
                        title: "E-Book Graphic Designer (Canva)",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage7
                        ],
                        star: "5",
                        link: ""
                    },
                    {
                        id: "8",
                        title: "E-Book English, TOEFL, & IELTS",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage8
                        ],
                        star: "5",
                        link: ""
                    },
                    {
                        id: "9",
                        title: "E-Book Digital Marketing",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage9
                        ],
                        star: "4.5",
                        link: ""
                    },
                    {
                        id: "10",
                        title: "E-Book Data Science",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage10
                        ],
                        star: "5",
                        link: ""
                    },
                    {
                        id: "11",
                        title: "E-Book Corporate Finance and Tax",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage11
                        ],
                        star: "4.5",
                        link: "",
                    },
                    {
                        id: "12",
                        title: "E-Book Career & Self Development",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage12
                        ],
                        star: "5",
                        link: ""
                    },
                    {
                        id: "13",
                        title: "E-Book AI Chat GPT",
                        dcprice: "123.000",
                        rprice: "",
                        image: [
                            LmsImage13
                        ],
                        star: "4.5",
                        link: ""
                    },
                ]
            }
        },
        {
            type: "E-Course",
            id: "2",
            properties:
            {
                products : [
                    {
                        id: "14",
                        title: "Canva Mastery Course: Rahasia Bisa Desain Dalam 30 Hari!",
                        dcprice: "49.000",
                        rprice: "459.000",
                        image: [
                            Ecourse1
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/kdxXb94"
                    },
                    {
                        id: "15",
                        title: "ChatGPT Mastery Course - Kursus Jadi Pakar ChatGPT",
                        dcprice: "19.000",
                        rprice: "99.000",
                        image: [
                            Ecourse2
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/On4jqrm"
                    },
                    {
                        id: "16",
                        title: "E-Course Facebook Ads Formula (1 milyar pertama dari facebook ads)",
                        dcprice: "499.000",
                        rprice: "",
                        image: [
                            Ecourse3
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/O96kj3q"
                    },
                    {
                        id: "17",
                        title: "E-Course Rahasia Hasilin 10 Juta/Bulan Instagram",
                        dcprice: "14.900",
                        rprice: "100.000",
                        image: [
                            Ecourse4
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/Qp1mbk1",
                    },
                    {
                        id: "18",
                        title: "E-Course Belajar Digital Marketing Dari GAPTEK Sampai Bisa PRAKTEK",
                        dcprice: "950.000",
                        rprice: "",
                        image: [
                            Ecourse5
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/88BPW8z"
                    },
                    {
                        id: "19",
                        title: "E-Course Template Instagram Supaya Lebih Menarik dan Informatif",
                        dcprice: "99.000",
                        rprice: "199.000",
                        image: [
                            Ecourse6
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/o7DVwX0"
                    },
                    {
                        id: "20",
                        title: "E-Course 200+ Power Point Template[Hak Jual Kembali-Keuntungan 100%]",
                        dcprice: "35.000",
                        rprice: "59.000",
                        image: [
                            Ecourse7
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/0M4lGbY"
                    },
                    {
                        id: "21",
                        title: "E-Course Template CV ATS + Body Email + Surat Lamaran Semua Bahasa",
                        dcprice: "49.500",
                        rprice: "50.000",
                        image: [
                            Ecourse8
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/Jd9z75m"
                    },
                    {
                        id: "22",
                        title: "E-Course Template Portfolio + CV ATS + Surat Lamaran + Body Email Siap Pakai",
                        dcprice: "15.000",
                        rprice: "49.000",
                        image: [
                            Ecourse9
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/yx1G9Pa"
                    },
                    {
                        id: "23",
                        title: "E-Course Template Portfolio + CV + Surat Lamaran + Body Email + Wawancara",
                        dcprice: "19.000",
                        rprice: "50.000",
                        image: [
                            Ecourse10
                        ],
                        star: "4.5",
                        link: "https://lynk.id/careertools/Meq4jGR"
                    },
                    {
                        id: "24",
                        title: "E-Course Corporate Finance and Tax",
                        dcprice: "250.000",
                        rprice: "300.000",
                        image: [
                            LmsImage11
                        ],
                        star: "4.5",
                        link: ""
                    },
                    {
                        id: "25",
                        title: "E-Course Career & Self Development",
                        dcprice: "120.000",
                        rprice: "150.000",
                        image: [
                            LmsImage12
                        ],
                        star: "5",
                        link: ""
                    },
                    {
                        id: "26",
                        title: "E-Course Data Science",
                        dcprice: "350.000",
                        rprice: "450.000",
                        image: [
                            LmsImage10
                        ],
                        star: "5",
                        link: ""
                    },
                    {
                        id: "27",
                        title: "E-Course Website Development",
                        dcprice: "250.000",
                        rprice: "330.000",
                        image: [
                            LmsImage1
                        ],
                        star: "4.5",
                        link: ""
                    },
                    {
                        id: "28",
                        title: "E-Course Microsoft Office (Fullstack)",
                        dcprice: "250.000",
                        rprice: "270.000",
                        image: [
                            LmsImage5
                        ],
                        star: "4.5",
                        link: ""
                    },
                    {
                        id: "29",
                        title: "E-Course Human Resource Development",
                        dcprice: "150.000",
                        rprice: "200.000",
                        image: [
                            LmsImage6
                        ],
                        star: "5",
                        link: ""
                    },
                ]
            }
        }
    ]
};