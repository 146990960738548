import React, { useState } from "react";
import {
    Button
} from "react-bootstrap";


const ButtonFill2 = ({ buttonName, widthCustom, paddingCustom, onClick, borderRadiusCustom, isActive }) => {

    const [isHovered, setIsHovered] = useState(false);

    return (

        <Button
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={
                {
                    backgroundColor: isHovered ? '#00134D' : (isActive ? '#00134D' : 'transparent'),
                    border: '1px solid #00134D',
                    borderRadius: borderRadiusCustom,
                    padding: paddingCustom,
                    cursor: 'pointer',
                    width: widthCustom,
                    fontSize: window.innerWidth < 576 ? '16px' : null,
                    color: isHovered ? '#FFFFFF' : (isActive ? '#FFFFFF' :'#00134D'),
                    transition: '0.3s all ease-in-out',
                    fontWeight: '600',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }
            }
        >
            <div className="d-flex gap-3 align-items-center justify-content-center">
                <div>
                    {buttonName}
                </div>
            </div>
        </Button>

    );

};

export default ButtonFill2;