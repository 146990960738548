import CEOIcon from "../images/icons/Businessman.svg";
import HRDIcon from "../images/icons/Goal.svg"
import DigitalIcon from "../images/icons/digital.png";
import BusinessIcon from "../images/icons/analytics.svg";
import CreativeIcon from "../images/icons/mind.svg";
import GraphicIcon from "../images/icons/Crayon.svg";
import WebsiteIcon from "../images/icons/Website.svg";


export const DepartmentData = {
    "type": "Department",
    "Collections": [
        {
            type: "Department",
            id: "1",
            properties:
            {
                title: "CEO Office",
                description: "Mendukung CEO dalam manajemen dan supervisi operasional, kelola jadwal, buat laporan, siapkan presentasi, dan koordinasikan komunikasi serta pertemuan penting.",
                icon: CEOIcon,
            }
        },
        {
            type: "Department",
            id: "2",
            properties:
            {
                title: "HRD",
                description: "Kembangkan strategi untuk menarik talenta terbaik, rancang program pelatihan untuk pengembangan karyawan, dan tangani masalah serta evaluasi kinerja secara efektif.",
                icon: HRDIcon,
            }
        },
        {
            type: "Department",
            id: "3",
            properties:
            {
                title: "Digital Marketing",
                description: "Kelola pemasaran digital melalui media sosial, email, dan situs web. Analisis data untuk optimalkan hasil, dan kembangkan strategi konten digital yang relevan.",
                icon: DigitalIcon,
            }
        },
        {
            type: "Department",
            id: "4",
            properties:
            {
                title: "Business Development",
                description: "Bangun hubungan dengan mitra dan klien untuk memperluas jaringan, identifikasi strategi baru, dan negosiasikan kontrak untuk kesepakatan menguntungkan.",
                icon: BusinessIcon,
            }
        },
        {
            type: "Department",
            id: "5",
            properties:
            {
                title: "Creative management",
                description: "Melibatkan pengembangan konsep kreatif untuk acara, pengelolaan proses produksi dari awal hingga selesai, dan penataan anggaran serta jadwal untuk memastikan acara berjalan lancar.",
                icon: CreativeIcon,
            }
        },
        {
            type: "Department",
            id: "6",
            properties:
            {
                title: "Graphic Designer",
                description: "Mencakup perancangan grafis brosur dan poster secara kolaboratif dengan tim kreatif, serta menjaga kualitas materi promosi untuk menyampaikan pesan dengan jelas.",
                icon: GraphicIcon,
            }
        },
        {
            type: "Department",
            id: "7",
            properties:
            {
                title: "Website Development",
                description: "Mengelola situs web perusahaan berfokus pada kinerja, keamanan, dan konten yang menarik untuk meningkatkan pengalaman pengguna dan mendukung keberhasilan situs.",
                icon: WebsiteIcon,
            }
        },
    ]
};