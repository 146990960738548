import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import Impact1Image from "../../assets/images/impact/uni.svg";
import Impact2Image from "../../assets/images/impact/telkom.svg";
import Impact3Image from "../../assets/images/impact/bca.svg";
import Impact4Image from "../../assets/images/impact/shopee.svg";
import Impact5Image from "../../assets/images/impact/pertamina.svg";
import Impact6Image from "../../assets/images/impact/tokopedia.svg";


import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const ImpactContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <Row>
                <Col xl={5}>
                    <div className="impact-box">
                        <div className="d-flex align-items-center" style={{gap: '10px'}}>
                            <div>
                                <p className="m-auto count">1500<span>+</span></p>
                            </div>
                            <div>
                                <p className="m-auto subtitle">Alumni Careertools ID telah diterima di Top Company</p>
                            </div>
                            <div className={window.innerWidth < 576 ? "d-none" :"d-flex"} style={{gap: '5px'}}>
                                <Image src={Impact1Image} loading="lazy"/>
                                <Image src={Impact2Image} loading="lazy"/>
                                <Image src={Impact3Image} loading="lazy"/>
                                <Image src={Impact4Image} loading="lazy"/>
                                <Image src={Impact5Image} loading="lazy"/>
                                <Image src={Impact6Image} loading="lazy"/>
                            </div>
                            <div className={window.innerWidth < 576 ? "d-none" :"d-flex"}>
                                <p className="m-auto"><strong>+++</strong></p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xl={4} className={window.innerWidth < 576 ? "mt-3" : null}>
                    <div className="impact-box">
                        <div className="d-flex align-items-center" style={{gap: '10px'}}>
                            <div>
                                <p className="m-auto count">100<span>+</span></p>
                            </div>
                            <div>
                                <p className="m-auto subtitle">Materi kelas karir, AI & digital tools sesuai dengan keinginanmu</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xl={3} className={window.innerWidth < 576 ? "mt-3" : null}>
                    <div className="impact-box">
                        <div className="d-flex align-items-center" style={{gap: '10px'}}>
                            <div>
                                <p className="m-auto count">50<span>+</span></p>
                            </div>
                            <div>
                                <p className="m-auto subtitle">Partner besar yang bermitra dengan kami</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ImpactContent;