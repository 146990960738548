import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import AOS from 'aos';

import BadgeSection from "../badge/Badge";

import { ReactComponent as CheckIcon } from "../../assets/images/icons/check-circle-fill.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";

import { BenefitData } from "../../assets/data/benefitData";

import 'aos/dist/aos.css';

import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const BenefitContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <Row>
                <Col xs={12} xl={12} className="d-flex justify-content-start" data-aos="fade-up">
                    <BadgeSection sectionTitle="Benefit dan Keuntungan" widthBackground={window.innerWidth < 576 ? "180px" : "200px"} />
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }} data-aos="fade-up">
                <Col xl={12}>
                    <div className="table-responsive">
                        <table className="table table-stripped">
                            <thead>
                                <th style={{ padding: '0', textAlign: 'center', fontSize: window.innerWidth < 576 ? '16px' : '18px', height: '100px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', background: '#00134D', color: '#FFFFFF' }}>
                                        Benefit dan Keuntungan
                                    </div>
                                </th>
                                <th style={{ padding: '0', textAlign: 'center', fontSize: window.innerWidth < 576 ? '16px' : '18px', height: '100px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', background: '#00134D', color: '#FFFFFF' }}>
                                        Careertools ID
                                    </div>
                                </th>
                                <th style={{ padding: '0', textAlign: 'center', fontSize: window.innerWidth < 576 ? '16px' : '18px', height: '100px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', background: '#00134D', color: '#FFFFFF' }}>
                                        Selain Careertools ID
                                    </div>
                                </th>
                            </thead>
                            <tbody>
                                {BenefitData.Collections.map((benefit) => {
                                    return (
                                        <tr key={benefit.id}>
                                            <td style={{ padding: '10px', fontSize: window.innerWidth < 576 ? '14px' : '16px', color: '#212529', height: '80px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                    {benefit.properties.benefit}
                                                </div>
                                            </td>
                                            <td style={{ padding: '10px 0', height: '80px' }}>
                                                <div className="d-flex justify-content-center align-items-center" style={{height: '100%'}}>
                                                    <div className="d-flex align-items-center" style={{ height: '20px', width: '20px' }}>
                                                        <CheckIcon />
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ padding: '10px 0', height: '80px' }}>
                                                <div className="d-flex justify-content-center align-items-center" style={{height: '100%'}}>
                                                    <div className="d-flex align-items-center" style={{ height: '20px', width: '20px' }}>
                                                        <CloseIcon />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default BenefitContent;