import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import { DepartmentData } from "../../assets/data/departmentData";

import BadgeSection from "../badge/Badge";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const DepartmentContent = () => {

    const location = useLocation();
    
        useEffect(() => {
            if (location.hash) {
                const element = document.getElementById(location.hash.substring(1));
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }, [location]);
    
        const handleContactClick = () => {
            if (location.pathname !== '/') {
                window.location.href = '/#contact-content';
            } else {
                document.getElementById('contact-content').scrollIntoView({ behavior: 'smooth' });
            }
        };;

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container>
            <Row>
                <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up">
                    <BadgeSection sectionTitle="Masuki dunia kami" widthBackground={window.innerWidth < 576 ? "140px" : "160px"} />
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col xs={12} xl={{ span: 10, offset: 1 }} className="d-flex justify-content-center" data-aos="fade-up">
                    <h1 className="text-center">Raih Kesuksesan dengan <strong>Tingkatkan Produktivitas</strong> dan Keahlian Bersama Kami</h1>
                </Col>
            </Row>
            <Row style={{ marginTop: window.innerWidth < 576 ? '20px' : '50px' }}>
                {DepartmentData.Collections.map((department) => {
                    return (
                        <Col xs={12} xl={3} data-aos="fade-up" className="mt-3" key={department.id}>
                            <div className="d-flex flex-column p-3 card-department" style={{ gap: '20px', border: '1px #E9ECF1 solid', height: '100%' }}>
                                <div className="d-flex gap-3 align-items-center department-title">
                                    <div className="d-flex justify-content-center align-items-center" style={{ background: '#00134D', height: window.innerWidth < 576 ? '35px' : '45px', width: window.innerWidth < 576 ? '35px' : '45px', borderRadius: '25px' }}>
                                        <Image src={department.properties.icon} alt="icon" style={{ width: window.innerWidth < 576 ? '20px' : '25px' }} />
                                    </div>
                                    <p>{department.properties.title}</p>
                                </div>
                                <div className="department-short-description">
                                    <p>{department.properties.description}</p>
                                </div>
                            </div>
                        </Col>
                    )
                })}
                {DepartmentData.Collections.length % 4 !== 0 && (
                    <Col
                        xs={12}
                        xl={3}
                        data-aos="fade-up"
                        className="mt-3"
                    >
                        <div
                            className="d-flex justify-content-center align-items-center p-3"
                            style={{
                                border: '1px #00134D solid',
                                borderRadius: '10px',
                                height: '100%',
                                backgroundColor: '#00134D',
                                cursor: 'pointer'
                            }}
                            onClick={handleContactClick}
                        >
                            <div className="d-flex flex-column" style={{ gap: '10px' }}>
                                <h3 style={{ color: '#FFFFFF', fontSize: '12px', fontWeight: 'bold' }}>APA YANG KAMI LAKUKAN</h3>
                                <p style={{ color: '#FFFFFF', fontSize: '20px', margin: 'auto' }}> Kami siap membantu Anda menemukan solusi terbaik untuk kebutuhan Anda.</p>
                            </div>
                        </div>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export default DepartmentContent;