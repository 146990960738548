import Profile1 from "../images/icons/profile-1.png";
import Profile2 from "../images/icons/profile-2.png";
import Profile3 from "../images/icons/profile-3.png";
import Profile4 from "../images/icons/profile-4.png";

export const TestimoniData = {
    "type": "Testimoni",
    "Collections": [
        {
            type: "Testimoni",
            id: "1",
            properties:
            {
                name: "Dinda Ayu",
                employment: "Videografer",
                text: "Program ini benar-benar membuka wawasan saya tentang inovasi kreatif. Kolaborasi dengan para profesional dari berbagai bidang sangat memperkaya pengalaman saya.",
                icon: Profile1
            }
        },
        {
            type: "Testimoni",
            id: "2",
            properties:
            {
                name: "Irvan Fahrezi",
                employment: "Wiraswasta",
                text: "Acara ini sangat inspiratif! Saya belajar banyak dari para mentor, dan jaringan yang terbentuk sangat mendukung perkembangan bisnis saya.",
                icon: Profile2
            }
        },
        {
            type: "Testimoni",
            id: "3",
            properties:
            {
                name: "Safira Putri",
                employment: "Product Development",
                text: "Saya sangat berterima kasih atas kesempatan ini. Program ini membantu saya mengembangkan ide-ide baru dan mempersiapkan saya untuk tantangan di masa depan.",
                icon: Profile3
            }
        },
        {
            type: "Testimoni",
            id: "4",
            properties:
            {
                name: "Akbar Taufik",
                employment: "Desain Grafis",
                text: "Inspirasi dari para mentor benar-benar membantu saya meningkatkan kualitas desain dan cara berkomunikasi dengan klien.",
                icon: Profile4
            }
        },
    ]
};