import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import { ServiceData } from "../../assets/data/serviceData";

import BadgeSection from "../badge/Badge";
import ButtonFill2 from "../button/ButtonFill2";
import ButtonFill3 from "../button/ButtonFill3";

// import ServicePriorityImage from "../../assets/images/general/service-content-image.svg";

import ServiceImage1 from "../../assets/images/general/service1.svg";

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const ServiceContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    const [selectedType, setSelectedType] = useState("Individuals");

    const handleButtonClick = (type) => {
        setSelectedType(type);
    };

    const servicesToDisplay = ServiceData.Collections.find(collection => collection.type === selectedType)?.properties.service || [];

    return (
        <Container>
            <Row>
                <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up">
                    <BadgeSection sectionTitle="Bootcamp hingga konsultasi" widthBackground={window.innerWidth < 576 ? "210px" : "225px"} />
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }} className="service-description">
                <Col xs={12} xl={7} data-aos="fade-up">
                    <Image src={ServiceImage1} style={{width: window.innerWidth < 576 ? '100%' : null}} loading="lazy"/>
                </Col>
                <Col xs={12} xl={5}>
                    <div className="d-flex flex-column gap-2" style={{marginTop: window.innerWidth < 576 ? '30px' : null}} data-aos="fade-up">
                        <h1>Memberdayakan Karier dan Bisnis dengan Careertools.</h1>
                        <p>Careertools mempercepat pertumbuhan karier melalui mentoring dan kursus, sambil mendorong kesuksesan bisnis melalui pelatihan, konsultasi, dan pemasaran digital.</p>
                    </div>
                    <div className="d-flex gap-2" style={{ marginTop: '30px', marginBottom: '40px' }} data-aos="fade-up">
                        <ButtonFill3 buttonName="Untuk Individu" widthCustom="192px" heightCustom="40px" borderRadiusCustom="20px" isActive={selectedType === "Individuals"} onClick={() => handleButtonClick("Individuals")} />
                        <ButtonFill2 buttonName="Untuk Perusahaan" widthCustom="192px" heightCustom="40px" borderRadiusCustom="20px" isActive={selectedType === "Corporates"} onClick={() => handleButtonClick("Corporates")} />
                    </div>
                    <div className="d-flex flex-column" style={{ gap: '31px' }} data-aos="fade-up">
                        {servicesToDisplay.map((service) => {
                            return (
                                <div className="d-flex align-items-center service-sample" style={{ gap: '30px' }} key={service.id}> 
                                    <div style={{ background: '#00134D', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', padding: '15px' }}>
                                        <Image src={service.icon} alt="icon" style={{width: window.innerWidth < 576 ? '20px' : null}}/>
                                    </div>
                                    <div>
                                        <p><span>{service.title}</span>. {service.description}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ServiceContent