import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from 'aos';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import BadgeSection from "../badge/Badge";

import WorkPlaceIcon from "../../assets/images/icons/work-place.svg";
import OccupationIcon from "../../assets/images/icons/occupation.svg";

import { MentorData } from "../../assets/data/mentorData";

import 'swiper/css';
import 'swiper/css/pagination';


import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const MentorContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Container style={{zIndex: '1'}}>
            <Row>
                <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up" style={{ zIndex: 1 }}>
                    <BadgeSection
                        sectionTitle="Professional Mentor"
                        widthBackground={window.innerWidth < 576 ? "160px" : "170px"}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: window.innerWidth < 576 ? "20px" : "30px" }}>
                <Col xs={12} xl={{ span: 8, offset: 2 }} className="d-flex justify-content-center" data-aos="fade-up" style={{ zIndex: 1 }}>
                    <h1 className="text-center" style={{ fontSize: window.innerWidth < 576 ? "24px" : null}}>
                        Mentor yang Berpengalaman untuk Membantumu <strong>Naik Kelas</strong>
                    </h1>
                </Col>
            </Row>
            <Row style={{ marginTop: window.innerWidth < 576 ? "30px" : '40px' }}>
                    <Col xl={12}>
                        <div style={{ height: 'auto', width: '100%', borderRadius: '8px' }} data-aos="fade-up">
                            <Swiper
                                slidesPerView={window.innerWidth < 576 ? '1' : '4'}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                    dynamicBullets: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                                style={{ height: '100%' }}
                            >
                                {MentorData.Collections.map((mentor) => {
                                    return (
                                        <SwiperSlide key={mentor.id} style={{cursor: 'pointer'}}>
                                            <div className="d-flex flex-column" style={{ width: '100%', background: '#FFFFFF', border: '1px solid #E9ECF1', padding: '15px', borderRadius: '10px', gap: '15px' }}>
                                                <div>
                                                    <Image src={mentor.properties.photo} style={{ width: '100%', height: 'auto', borderRadius: '8px' }} loading="lazy"/>
                                                </div>
                                                <div className="d-flex flex-column" style={{ gap: '8px' }}>
                                                    <p style={{ margin: 'auto 0' }}><strong>{mentor.properties.name}</strong></p>
                                                    <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                                        <Image src={WorkPlaceIcon} />
                                                        <p style={{ margin: 'auto 0' }}>{mentor.properties.institution}</p>
                                                    </div>
                                                    <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                                        <Image src={OccupationIcon} />
                                                        <p style={{ margin: 'auto 0' }}>{mentor.properties.occupation}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
        </Container>

    );
};

export default MentorContent;