import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from "aos";

import { ProductData } from "../../assets/data/lmsData";

import HomeLayout from "../../layouts/home/HomeLayout";

import ButtonFill2 from "../../components/button/ButtonFill2";
import Dropdown from "../../components/dropdown/Dropdown";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const Product = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    const [filterQuery, setFilterQuery] = useState('E-Course');

    const handleFilterQuery = (option) => {
        setFilterQuery(option);
    };

    const productClickbait = ProductData.Collections.find(collection => collection.type === filterQuery);

    const productToDisplay = ProductData.Collections.find(collection => collection.type === filterQuery)?.properties.products || [];

    return (
        <HomeLayout>
            <Container>
                <Row className="product-head-title">
                    <Col xl={6} data-aos="fade-up">
                        <h1>Lihat pilihan bundle e-book / e-course yang sesuai dengan minat anda.</h1>
                    </Col>
                </Row>
                <Row className="product-list-query">
                    <Col xs={12} xl={2} className="d-flex justify-content-start">
                        <h5>{productClickbait.type} Terlaris</h5>
                    </Col>
                    <Col xs={9} xl={{ span: 3, offset: 5 }} className={window.innerWidth < 576 ? "d-flex justify-content-start gap-2 mt-2" : "d-flex justify-content-end gap-2"}>
                        <h5>Filter Berdasarkan</h5>
                        <Dropdown onChange={handleFilterQuery} />
                    </Col>
                    <Col xs={3} xl={2} className={window.innerWidth < 576 ? "d-flex justify-content-end mt-2" : "d-flex justify-content-end"}>
                        <h5>Hasil: [{productToDisplay.length}]</h5>
                    </Col>
                </Row>
                <Row className="product-list-sell">
                    {productToDisplay.map((product) => {
                        return (
                            <Col xl={3} key={product.id} className="mt-4" data-aos="fade-up">
                                <div className="d-flex flex-column gap-3">
                                    <div style={{ height: '300px', width: '100%', backgroundColor: '#E9ECF1', borderRadius: '10px' }}>
                                        <Image src={product.image[0]} alt="ProductImage" style={{ height: '300px', width: '100%', borderRadius: '10px' }} loading="lazy"/>
                                    </div>
                                    <div className="d-flex flex-column gap-2">
                                        <p>{product.title}</p>
                                        {product.id < 14 ? (
                                            <p>Rp. <s>399.000,00</s> <strong>{product.dcprice}</strong></p>
                                        ) : (
                                            <p>Rp. <s style={{ color: '#CACACA' }}>{product.rprice}</s> <strong>{product.dcprice}</strong></p>
                                        )}
                                    </div>
                                    <div>
                                        {/* <ButtonFill2 buttonName="Lihat Detail" widthCustom="140px" paddingCustom="4px 6px" onClick={() => navigate(`/products/detail/${product.id}`)}/> */}
                                        {product.link !== "" ? (
                                            <ButtonFill2 buttonName="Beli Sekarang" widthCustom="140px" paddingCustom="4px 6px" onClick={() => window.location.href = product.link}/>
                                        ) :
                                            <ButtonFill2 buttonName="Coming soon" widthCustom="140px" paddingCustom="4px 6px" />
                                        }
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </HomeLayout>
    );
};

export default Product;