import React from "react";

const BadgeSection = ({ sectionTitle, widthBackground, marginCustom }) => {

    return (

        <div style={{ width: widthBackground, background: "rgba(0,19,77,0.2)", padding: '4px', borderRadius: '20px', marginTop: marginCustom}}>
            <div style={{background: 'transparent'}}>
                <p style={{ margin: 'auto 0', textAlign: 'center', fontSize: window.innerWidth < 576 ? '14px' : '16px', background: 'transparent', color: '#00134D'}}>{sectionTitle}</p>
            </div>
        </div>

    );

};

export default BadgeSection;