import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image,
    Form
} from "react-bootstrap";
import AOS from 'aos';
import { useSnackbar } from 'notistack';
import axios from "axios";

import BadgeSection from "../badge/Badge";
import ButtonFill from "../button/ButtonFill";

import { ReactComponent as EmailIcon } from "../../assets/images/icons/envelope-check-fill.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/icons/telephone-fill.svg";
// import ContactImage from "../../assets/images/general/contact-image.svg";

import ContactImage1 from "../../assets/images/general/contact1.png";

import 'aos/dist/aos.css';
import "../../assets/css/style.css"
import "../../assets/css/responsive.css"

const ContactContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmitEmail = async (e) => {

        e.preventDefault();

        try {

            const response = await axios.post(
                'https://formspree.io/f/xwpkklgw',
                {
                    name: `${firstName} ${lastName}`,
                    phone,
                    email,
                    message
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            if (response) {
                enqueueSnackbar('Email sent successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 });
            }

        } catch (err) {
            enqueueSnackbar('An error occurred while sending the email', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 });
        }
    };

    return (
        <Container>
            <Row>
                <Col xs={12} xl={12} className="d-flex justify-content-center" data-aos="fade-up">
                    <BadgeSection sectionTitle="Kami siap membantu" widthBackground={window.innerWidth < 576 ? "160px" : "175px"} />
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col xs={12} xl={5}>
                    <div className="d-flex flex-column gap-4 contact-information">
                        <Image src={ContactImage1} alt="ContactImage" data-aos="fade-up"/>
                        <div className="d-flex gap-4" style={{ height: window.innerWidth < 576 ? '70px' : '90px', width: '100%', backgroundColor: '#E9ECF1', borderRadius: '10px', padding: window.innerWidth < 576 ? '15px 18px' : '20px 18px' }} data-aos="fade-up">
                            <div className="d-flex justify-content-center align-items-center" style={{ height: window.innerWidth < 576 ? '40px' : '50px', width: window.innerWidth < 576 ? '40px' : '50px', backgroundColor: '#00134D', borderRadius: '10px' }}>
                                <EmailIcon style={{height: window.innerWidth < 576 ? '25px' : null}}/>
                            </div>
                            <div className="mt-auto">
                                <h1>Email</h1>
                                <p>careertoolsid@gmail.com</p>
                            </div>
                        </div>
                        <div className="d-flex gap-4" style={{ height: window.innerWidth < 576 ? '70px' : '90px', width: '100%', backgroundColor: '#E9ECF1', borderRadius: '10px', padding: window.innerWidth < 576 ? '15px 18px' : '20px 18px' }} data-aos="fade-up">
                            <div className="d-flex justify-content-center align-items-center" style={{ height: window.innerWidth < 576 ? '40px' : '50px', width: window.innerWidth < 576 ? '40px' : '50px', backgroundColor: '#00134D', borderRadius: '10px' }}>
                                <PhoneIcon style={{height: window.innerWidth < 576 ? '25px' : null}}/>
                            </div>
                            <div className="mt-auto">
                                <h1>Phone</h1>
                                <p>+62 895-4135-26937</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={{ span: 6, offset: 1 }}>
                    <div className="contact-form">
                        <div className="d-flex flex-column gap-2" data-aos="fade-up">
                            <h1>Hubungi Kami</h1>
                            <p>Memiliki pertanyaan atau umpan balik? Kami siap membantu. Kirimkan pesan kepada kami, dan kami akan merespons dalam waktu 24 jam.</p>
                        </div>
                        <div className="form-message" style={{ marginTop: '40px' }}>
                            <Form>
                                <Row className="mt-2">
                                    <Col xs={12} xl={6} data-aos="fade-up">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Nama Depan</Form.Label>
                                            <Form.Control type="text" placeholder="Nama depanmu..." style={{ height: window.innerWidth < 576 ? '40px' : '50px' }} onChange={(e) => setFirstName(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} xl={6} data-aos="fade-up">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                            <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Nama Belakang</Form.Label>
                                            <Form.Control type="text" placeholder="Nama belakangmu..." style={{ height: window.innerWidth < 576 ? '40px' : '50px' }} onChange={(e) => setLastName(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={12} xl={12} data-aos="fade-up">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                            <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Email</Form.Label>
                                            <Form.Control type="email" placeholder="name@example.com" style={{ height: window.innerWidth < 576 ? '40px' : '50px' }} onChange={(e) => setEmail(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={12} xl={12} data-aos="fade-up">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                            <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Nomor Handphone</Form.Label>
                                            <Form.Control type="text" placeholder="Nomor handphonemu..." style={{ height: window.innerWidth < 576 ? '40px' : '50px' }} onChange={(e) => setPhone(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-2" data-aos="fade-up">
                                    <Col xs={12} xl={12}>
                                        <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Pesan</Form.Label>
                                            <Form.Control as="textarea" rows={window.innerWidth < 576 ? '3' : '6'} onChange={(e) => setMessage(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} xl={12} data-aos="fade-up">
                                        <ButtonFill
                                            onClick={handleSubmitEmail}
                                            widthCustom="100%"
                                            buttonName="Kirim Pesan"
                                            heightCustom="50px"
                                            borderRadiusCustom="25px"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ContactContent;