import React from "react";
import HomeLayout from "../../layouts/home/HomeLayout";
import MainContent from "../../components/home/MainContent";
import ImpactContent from "../../components/home/ImpactContent";
import AboutUsContent from "../../components/home/AboutUsContent";
import VissionMissionContent from "../../components/home/VissionMissionContent";
import ServiceContent from "../../components/home/ServiceContent";
import PartnerContent from "../../components/home/PartnerContent";
import DepartmentContent from "../../components/home/DepartmentContent";
import BenefitContent from "../../components/home/BenefitContent";
import InternationalPanelistContent from "../../components/home/InternationalPanelistContent";
import MentorContent from "../../components/home/MentorContent";
import TestimonyContent from "../../components/home/TestimonyContent";
import ContactContent from "../../components/home/ContactContent";

import "../../assets/css/style.css";

const Home = () => {

    return (

        <HomeLayout>

            <div id="main-content">
                <MainContent />
            </div>

            <div id="impact-content">
                <ImpactContent/>
            </div>

            <div id="about-us-content">
                <AboutUsContent />
            </div>

            <div id="vission-mission-content">
                <VissionMissionContent />
            </div>

            <div id="service-content">
                <ServiceContent />
            </div>

            <div id="partner-content">
                <PartnerContent />
            </div>

            <div id="department-content">
                <DepartmentContent />
            </div>
            
            <div id="benefit-content">
                <BenefitContent />
            </div>
            
            <div id="mentor-content">
                <MentorContent />
            </div>

            <div id="international-panelist-content">
                <InternationalPanelistContent />
            </div>
            
            <div id="testimony-content">
                <TestimonyContent />
            </div>

            <div id="contact-content">
                <ContactContent />
            </div>

        </HomeLayout>

    );

};

export default Home;