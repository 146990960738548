import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image
} from "react-bootstrap";
import AOS from "aos";

import { EventData } from "../../assets/data/eventData";

import HomeLayout from "../../layouts/home/HomeLayout";

import { ReactComponent as CheckIcon } from "../../assets/images/icons/check-circle-fill.svg";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const Event = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <HomeLayout>
            <Container>
                <Row className="event-head-title">
                    <Col xl={6} data-aos="fade-up">
                        <h1>Jelajahi berbagai program yang tersedia sesuai minat anda.</h1>
                    </Col>
                </Row>
                <Row className="list-event">
                    {EventData.Collections.map((item) => {
                        return (
                            <Col xl={4} key={item.id} className="mt-4" data-aos="fade-up">
                                <div className="d-flex flex-column gap-3">
                                    <div style={{ height: window.innerWidth < 576 ? '400px' : '500px', width: '100%', backgroundColor: '#E9ECF1', borderRadius: '10px' }}>
                                        <Image src={item.properties.image} style={{ height: '100%', width: '100%', borderRadius: '10px' }} loading="lazy" />
                                    </div>
                                    <div className="d-flex flex-column gap-2">
                                        <h1>{item.properties.title}</h1>
                                        {item.properties.finish === "yes" ? (
                                            <div className="d-flex">
                                                <div className="d-flex gap-2 align-items-center">
                                                    <p style={{ fontWeight: "bold" }}>Acara Selesai</p>
                                                    <div className="d-flex align-items-center" style={{ height: '20px', width: '20px' }}>
                                                        <CheckIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex justify-content-between">
                                                <p>Slot tersedia:</p>
                                                {item.properties.quota !== "" ? (
                                                    <p style={{ fontWeight: "bold" }}>Tersisa {item.properties.quota} kuota</p>
                                                ) : (
                                                    <p>-</p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {item.properties.finish === "yes" ? (
                                        <div>
                                            {item.properties.link !== "" ? (
                                                <h2 onClick={() => window.location.href = `${item.properties.link}`}>Lihat Portfolio</h2>
                                            ) : null
                                            }
                                        </div>
                                    ) : (
                                        <div>
                                            {item.properties.link !== "" ? (
                                                <h2 onClick={() => window.location.href = `${item.properties.link}`}>Daftar Sekarang</h2>
                                            ) : null
                                            }
                                        </div>
                                    )}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </HomeLayout>
    );
};

export default Event;